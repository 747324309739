import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'

import { space } from '../constants/length'
import { Hsinchu, LightGrey, Primary, Taichung, Tainan, Taipei, White } from '../constants/newColor'
import { Tag } from '../constants/newText'
import { Location } from '../types/types'
import { getClinicInfo } from './Clinic/getClinicInfo'

interface ButtonProps {
  clinic?: Location | 'none'
}
const TagButton = styled.span<ButtonProps>`
${Tag}
  background-color: ${props => props.clinic === Location.Taipei && Taipei};
  background-color: ${props => props.clinic === Location.Hsinchu && Hsinchu};
  background-color: ${props => props.clinic === Location.Taichung && Taichung};
  background-color: ${props => props.clinic === Location.Tainan && Tainan};
  background-color: ${props => !props.clinic && LightGrey};

  color: ${props => (props.clinic ? White : Primary)};
  padding: ${space.xs}px 12px;
  border-radius: 40px;
  border: 1px solid ${Primary};
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    filter: opacity(75%);
  }
`

const Link = styled.a`
  display: inline-block;
`

interface WrapperProps {
  m?: string
  p?: string
}
const TagsWrapper = styled.div<WrapperProps>`
  margin: ${props => (props.m ? props.m : '0')};
  padding: ${props => (props.p ? props.p : '0')};
`
const Icon = styled.img``
interface TagsProps {
  type?: Location
  title: string
  img?: string
  href?: string
  m?: string
  p?: string
}
const Tags = (props: TagsProps) => {
  const { type, title, img, href, m, p } = props
  const { formatMessage } = useIntl()

  const clinicHref = getClinicInfo({ location: type, value: 'route' })
  return (
    <TagsWrapper m={m} p={p}>
      <Link href={href || (type ? clinicHref : '/')}>
        <TagButton clinic={type}>
          {title}
          {img && <Icon src={img} alt={`${formatMessage({ id: `location.${type}` })}Line`} />}
        </TagButton>
      </Link>
    </TagsWrapper>
  )
}

export default Tags
