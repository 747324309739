import React from 'react'
import Helmet from 'react-helmet'

const defaultTitle = '日不落牙醫集團｜台北、新竹、台中、台南 矯正專科團隊'
const defaultKeywords = '日不落牙醫、連鎖牙醫、牙醫推薦、隱形矯正、陶瓷貼片、牙齒美白'
const defaultDescription =
  '我們是牙齒矯正、陶瓷貼片的美學專家，提供SOV舒服美教育中心、矯正專科（隱形牙套、戴蒙矯正）、全瓷牙冠、人工植牙、兒童牙科等，遍布台北、新竹、台中、台南，提供給您最專業舒適的醫療環境。'
const baseURL = process.env.SITE_URL || 'https://sunlight.dentist'
const ogImageURL = `${baseURL}/static/img-og.jpg`

interface Props {
  metaDescription?: string
  keywords?: string
  title?: string
}

const name = '日不落牙醫集團'

export default function Seo(props: Props): React.ReactElement {
  const { metaDescription = defaultDescription, keywords = defaultKeywords, title = defaultTitle } = props

  return (
    <Helmet>
      <html lang='zh-TW' />
      <title>{title}</title>
      {/* General tags */}
      <meta name='title' content={title} />
      <meta name='description' content={metaDescription} />
      <meta name='image' content={ogImageURL} />
      <meta name='keywords' content={keywords} />

      {/* Schema.org tags */}

      {/* OpenGraph tags */}
      <meta property='og:description' content={metaDescription} name='description' />
      <meta property='og:image' content={ogImageURL} />
      <meta property='og:name' content={name} />
      <meta property='og:title' content={title} />
      <meta property='og:type' content='website' />
      <meta property='og:url' content={baseURL} />

      {/* Twitter Card tags */}
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={metaDescription} />
      <meta name='twitter:image' content={ogImageURL} />
      <meta property='twitter:url' content={baseURL} />
    </Helmet>
  )
}
